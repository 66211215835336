<div class="body-wrap  bg-nav">
    <div class="container-fluid">
        <div class="row">
            <nav class="navbar  " role="navigation">
                <div class="container-fluid">
                    <div class="navbar-header">
                        <button type="button" class="navbar-toggle" data-toggle="collapse"
                            data-target="#bs-example-navbar-collapse-1">
                            <span class="sr-only">Toggle

                            </span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>
                        <a class="navbar-brand" routerLink="/dashboard">
                            <img class="logo" src="../../assets/logo-tanay-nav.png" />
                            <!-- <img class="logo-name" src="../../assets/Tanay-Logo-White.png" /> -->
                        </a>
                    </div>

                    <!-- Collect the nav links, forms, and other content for toggling -->
                    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1"
                        [ngClass]="status ? 'show' : ''">

                        <ul class="nav navbar-nav">
                            <li class="nav-item  " [routerLinkActive]="['is-active']"><a class="nav-link"
                                    routerLink="/dashboard">
                                    Dashboard</a></li>
                            <li class="nav-item " [routerLinkActive]="['is-active']"><a class="nav-link "
                                    routerLink="/clientlist"> Clients</a></li>
                        </ul>

                        <ul class="nav navbar-nav navbar-right">

                            <li class="dropdown cursor">
                                <a class="dropdown-toggle" data-toggle="dropdown"> {{ firstName + ' ' +
                                    lastName}}
                                    <!-- <b class="caret"></b> -->
                                    <mat-icon class="down-arrow">keyboard_arrow_down</mat-icon>
                                </a>
                                <ul class="dropdown-menu ">
                                    <li><a routLink="/dashboard">Profile</a></li>
                                    <li><a (click)="LogOut()">Logout</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <!-- /.navbar-collapse -->
                </div>
                <!-- /.container-fluid -->
            </nav>
        </div>
    </div>
</div>