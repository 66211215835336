import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService {

  constructor(private router: Router) { }

  canActivate(): any {
    if (localStorage.getItem('currentUser')) {
      let data = JSON.parse(localStorage.getItem('currentUser') || '{}');
      if (data) {
        return true;
      }
    }

    this.router.navigate(['/login']);
    return false;
  }
}
