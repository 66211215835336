import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/notification.service';

@Component({
  selector: 'update-test-credits',
  templateUrl: './update-test-credits.component.html',
  styleUrls: ['./update-test-credits.component.scss']
})
export class UpdateTestCreditsComponent implements OnInit {
  public updateCredits: FormGroup;

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('updateCredit', { static: true }) updateCredit: TemplateRef<any>
  constructor(public dialog: MatDialog, private notificationService: NotificationService) { }


  ngOnInit(): void {
    this.updateCredits = new FormGroup({
      value: new FormControl(null, [
        Validators.required,
        Validators.min(1),
        Validators.max(1000000),
      ]),
    });
  }

  // get f() {
  //   return this.updateCredits.controls;
  // }


  showPopup(data: string) {
    this.updateCredits.setValue(({ value: data }))
    this.dialog.open(this.updateCredit, { minWidth: '650px' });
  }

  savePopup() {
    if (this.updateCredits.value.value <= 0 || this.updateCredits.value.value === '') {
      this.notificationService.warn('TestCredits must be a non-zero positive number');
    } else {
      this.dialog.closeAll();
      this.onClose.emit(this.updateCredits.value);
    }
  }
}
