<ng-template #updateCredit>
  <div class="dialog-container">
    <div class="head-container">
      <div class="row">
        <div class="col-lg-10">
          <div class="dialog-heading">
            <h2 mat-dialog-title>Add Credits</h2>
          </div>
        </div>
        <div class="col-lg-2 text-right">
          <div class="btn-close">
            <button mat-icon-button mat-dialog-close aria-label="Close Dialog"><mat-icon>close</mat-icon></button>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-content class="mat-typography">
      <form [formGroup]="updateCredits">
        <div class="row">
          <div class="col-lg-12">
            <label>Test Credits</label><span class="red-asterisk">*</span>
          </div>
          <div class="col-lg-12">
            <input type="number" class="form-control" placeholder="" formControlName="value"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');">
            <div *ngIf="updateCredits.controls.value.invalid && (updateCredits.controls.value.dirty || updateCredits.controls.value.touched)">
              <div class="alert alert-danger" *ngIf="updateCredits.controls.value.errors?.required"> Test credits is required</div>
              <div class="alert alert-danger" *ngIf="updateCredits.controls.value.errors?.max"> Maximum 10,00,000 credits limits allowed</div>
            </div>
          </div>
        </div>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <div class="disp">
        <button mat-flat-button color="" class="mat-focus-indicator btn-bg-color mat-raised-button mat-button-base" aria-label="Save"
          (click)="savePopup()" [disabled]='updateCredits.controls.value.invalid'>Save</button>
      </div>
    </mat-dialog-actions>
  </div>
</ng-template>
