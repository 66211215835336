import { identifierModuleUrl } from '@angular/compiler';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/shared/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  isSubmitted = false;
  loginSpinner = false;
  loginErrorMessage: string;
  isLoginError = false;
  userServiceSubscription$: Subscription;
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  login(): void {
    this.loginSpinner = true;
    this.isSubmitted = true;
    if (this.loginForm.valid) {
      this.userServiceSubscription$ = this.userService
        .login(this.loginForm.value.email, this.loginForm.value.password)
        .subscribe(
          (data: any) => {
            console.log('credentials', data);
            if (data) {
              if (data?.data.roleDetails.privileges[0] === 'SUPER-ADMIN') {
                this.userService.isHeaderVisible$.next(true);
                this.router.navigate(['/dashboard']);
              } else {
                this.userService.isHeaderVisible$.next(false);
                this.isLoginError = true;
                this.loginErrorMessage = 'You are not a super admin';
                this.loginForm.reset();
              }
            }
          },
          (error) => {
            this.isLoginError = true;
            this.loginErrorMessage = 'Invalid username or password';
            this.loginForm.reset();
            console.log(error);
          }
        );
    } else {
      this.isLoginError = true;
      this.loginErrorMessage = 'Invalid username or password';
    }
  }

  ngOnDestroy(): void {
    this.userServiceSubscription$.unsubscribe();
  }
}
