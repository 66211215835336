import { Component, OnInit } from '@angular/core';
import { UserService } from './shared/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'tanayadmin';
  isHeaderVisible = false;
  currentUser: any;
  constructor(private userService: UserService) {
    // console.log('app constructor called');
    this.userService.isHeaderVisible$.subscribe(isVisible => {
      this.isHeaderVisible = isVisible;
    });
  }
  ngOnInit(): void {
    if(this.currentUser){
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (this.currentUser.roleDetails.privileges[0] === 'SuperAdmin') {
        this.userService.isHeaderVisible$.next(true);
      }
    }
  }
}
