import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserService } from '../shared/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  status = false;

  show = false;
  collectionData$: Subscription | undefined;

  collectionData: any;

  userDetails: any = {
    parentId: 0,
    userId: 0,
    roleDetails: {
      id: 0,
      name: '',
      description: '',
      privileges: [],
    },
    userName: '',
  };
  firstName: string;
  lastName: string;

  constructor(private router: Router, private userService: UserService) { }

  ngOnInit(): void {
    if (
      JSON.parse(localStorage.getItem('currentUser') || '{}').firstName ||
      JSON.parse(localStorage.getItem('currentUser') || '{}').firstName
    ) {
      this.firstName = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      ).firstName;
      this.lastName = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      ).lastName;
    } else {
      this.firstName = '';
      this.lastName = '';
    }
  }

  LogOut(): void {
    localStorage.removeItem('currentUser');
    this.userService.isHeaderVisible$.next(false);
    this.router.navigate(['/login']);
  }
}
