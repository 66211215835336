<div class="login-form-container">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8 logo-container">
                <div class="">
                    <div class="logo-wrapper">
                        <div class="logo">
                            <div><img src="../../assets/logo-tanay.png" alt="Logo Tanay"></div>
                        </div>
                        <div class="logo-content">
                            <span>
                                Cloud-based, API-first assessment platform that puts you in control. Deliver online
                                assessment at any scale and save time & money. A modern platform that accelerates the
                                omnichannel delivery of online tests to life as part of your system faster.
                            </span>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-4 login-form">
                <div id="login" class="form-container">
                    <div id="login-row" class="justify-content-center align-items-center">
                        <div id="login-box">
                            <div id="login-column" class="login-column-c">
                                <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
                                    <p class="text-center login-title">Login to your Account</p>
                                    <div *ngIf="isLoginError" class="error-message">{{ loginErrorMessage }}</div>
                                    <label for="username" class="text-info">Email <span
                                            class="error">*</span></label><br>
                                    <input type="text" class="form-control" formControlName="email" required>
                                    <div class="alert  alert-danger"
                                        *ngIf="loginForm?.get('email')?.hasError('required') && loginForm?.get('email')?.touched">
                                        Email is required
                                    </div>
                                    <label for="password" class="text-info">Password <span
                                            class="error">*</span></label><br>
                                    <input type="password" class="form-control" formControlName="password">
                                    <div class="alert  alert-danger"
                                        *ngIf="loginForm?.get('password')?.hasError('required') && loginForm?.get('password')?.touched">
                                        Password is required
                                    </div>
                                    <div class="form-group">
                                        <div><a href="" class="no-underline">Forgot password?</a></div>
                                        <div>
                                            <button type="submit" class="btn-login btn-bg-color" mat-flat-button>
                                                Login
                                            </button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>