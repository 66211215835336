<div class="main-content-wrapper">
  <div class="content-wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="filter-section">
            <button mat-raised-button routerLink="/clientlist">
              <i class="fa fa-angle-left"></i>
              <span> Back</span>
            </button>
          </div>
          <div class="title filter-section">
            <div>Client Registration</div>
          </div>
          <div class="btn-wrapper">
            <button mat-raised-button class="btn-bg-color" color="accent" [disabled]="!clientForm.valid" (click)="registerClient()"> Save
            </button>
          </div>
          <div class="tabs">
            <form [formGroup]="clientForm">
              <div class="input-class">
                <label>Client Name<span class="red-asterisk">*</span></label>
                <input type="text" class="form-control" formControlName="clientName" maxlength="30">
                <div *ngIf="clientForm.value.clientName.length===30" class="alert alert-danger">Name should be between 2 to 30 characters </div>
                <div *ngIf="f.clientName.invalid && (f.clientName.dirty || f.clientName.touched)" class="alert alert-danger">
                  <div *ngIf="f.clientName.errors?.pattern">Numbers and special characters are not allowed </div>
                  <div *ngIf="f.clientName.errors?.required">Please enter valid client name</div>
                  <div *ngIf="f.clientName.errors?.minlength">Name should be between 2 to 30 characters</div>
                </div>
              </div>
              <div class="input-class">
                <label>Client Url<span class="red-asterisk">*</span></label>
                <input type="text" class="form-control" formControlName="clientUrl" maxlength="30">
                <div *ngIf="clientForm.value.clientUrl.length===30" class="alert alert-danger">Url should be between 2 to 30 characters </div>
                <div *ngIf="f.clientUrl.invalid && (f.clientUrl.dirty || f.clientUrl.touched)" class="alert alert-danger">
                  <div *ngIf="f.clientUrl.errors?.required">Please enter valid client url </div>
                  <div *ngIf="f.clientUrl.errors?.minlength">Url should be between 2 to 30 characters</div>
                  <div *ngIf="f.clientUrl.errors?.maxlength">Url should be between 2 to 30 characters</div>
                </div>
              </div>

              <div class="input-class">
                <label>Language</label>
                <select class="form-control" name="langid" formControlName="langid">
                  <option *ngFor="let l of languageData" [value]="l.langId"
                  [attr.data-label]="l.languagecode">{{l.language}}</option>
                </select>
              </div>

              <div class="input-class">
                <label>Time Zone<span class="red-asterisk">*</span></label>
                <select class="form-control" name="timezone" formControlName="timezoneName">
                  <option *ngFor="let option of timeZoneArr" [value]="option?.standardname">
                    {{option?.standardname}}
                  </option>
                </select>
                <div *ngIf="f.timezoneName.invalid && (f.timezoneName.dirty || f.timezoneName.touched)" class="alert alert-danger">
                  <div *ngIf="f.timezoneName.errors?.required">Please select valid time zone </div>
                </div>
              </div>
              <div class="input-class">
                <label>Test Credits<span class="red-asterisk">*</span></label>
                <input type="number" class="form-control" formControlName="testcredits" min="0"
                  (input)="enforceMaxLength($event.target.value, 'testcredits')">
                <div *ngIf="f.testcredits.invalid && (f.testcredits.dirty || f.testcredits.touched)" class="alert alert-danger">
                  <!-- <div *ngIf="f.testcredits?.errors.max"> Maximum 10,00,000 credits limits allowed</div> -->
                  <div *ngIf="f.testcredits?.errors.required">Please enter valid Test Credits</div>
                  <div *ngIf="f.testcredits.errors?.pattern">Test Credits should be positive number</div>
                </div>
              </div>
              <div class="input-class">
                <label>Number of users<span class="red-asterisk">*</span></label>
                <input type="number" class="form-control" formControlName="maxnumberofusers">
                <div *ngIf="f.maxnumberofusers.invalid && (f.maxnumberofusers.dirty || f.maxnumberofusers.touched)" class="alert alert-danger">
                  <div *ngIf="f.maxnumberofusers.errors?.required">Please enter valid number of users </div>
                </div>
              </div>
              <div class="input-class">
                <label>Evaluation mode<span class="red-asterisk">*</span></label>
                <mat-radio-group aria-label="Select an option" formControlName="evaluationMode">
                  <mat-radio-button value="1">Survey</mat-radio-button>
                  <mat-radio-button value="2">Survey + Test</mat-radio-button>
                </mat-radio-group>
                <div *ngIf="f.evaluationMode.invalid && (f.evaluationMode.dirty || f.evaluationMode.touched)" class="alert alert-danger">
                  <div *ngIf="f.evaluationMode.errors?.required">Please choose evaluation mode </div>
                </div>
              </div>
              <div class="input-class">
                <label>First Name<span class="red-asterisk">*</span></label>
                <input type="text" class="form-control" formControlName="firstName" maxlength="30">
                <div *ngIf="clientForm.value.firstName.length===30" class="alert alert-danger">Name should be between 2 to 30 characters </div>
                <div *ngIf="f.firstName.invalid && (f.firstName.dirty || f.firstName.touched)" class="alert alert-danger">
                  <div *ngIf="f.firstName.errors?.pattern">Special characters, numbers and blank spaces are not allowed </div>
                  <div *ngIf="f.firstName.errors?.required">Please enter valid first name </div>
                  <div *ngIf="f.firstName.errors?.minlength">Name should be between 2 to 30 characters</div>
                  <div *ngIf="f.firstName.errors?.maxlength">Name should be between 2 to 30 characters </div>
                </div>
              </div>
              <div class="input-class">
                <label>Last Name<span class="red-asterisk">*</span></label>
                <input type="text" class="form-control" formControlName="lastName" maxlength="30">
                <div *ngIf="clientForm.value.lastName.length===30" class="alert alert-danger">Name should be between 2 to 30 characters </div>
                <div *ngIf="f.lastName.invalid && (f.lastName.dirty || f.lastName.touched)" class="alert alert-danger">
                  <div *ngIf="f.lastName.errors?.pattern">Special characters, numbers and blank spaces not allowed </div>
                  <div *ngIf="f.lastName.errors?.required">Please enter valid last name </div>
                  <div *ngIf="f.lastName.errors?.minlength">Name should be between 2 to 30 characters</div>
                  <div *ngIf="f.lastName.errors?.maxlength">Name should be between 2 to 30 characters </div>
                </div>
              </div>
              <div class="input-class">
                <label>Phone Number<span class="red-asterisk">*</span></label>
                <input type="number" class="form-control" formControlName="phone" (input)="enforceMaxLength($event.target.value, 'phone')"
                  maxlength="24">
                <div *ngIf="clientForm.value.phone.length===24" class="alert alert-danger">Phone number should be 24 characters</div>
                <div *ngIf="f.phone.invalid && (f.phone.dirty || f.phone.touched)" class="alert alert-danger">
                  <div *ngIf="f.phone.errors?.required">Please, Enter Contact Number.</div>
                  <div *ngIf="f.phone.errors?.pattern">Please enter contact number prefix with country code.</div>
                  <div *ngIf="f.phone.errors?.maxlength">Phone number should be 24 characters </div>
                </div>
              </div>
              <div class="input-class">
                <label>SubDomain Name<span class="red-asterisk">*</span></label>
                <input type="text" class="form-control" formControlName="subDomainName">
                <div *ngIf="f.subDomainName.invalid && (f.subDomainName.dirty || f.subDomainName.touched)" class="alert alert-danger">
                  <div *ngIf="f.subDomainName.errors?.required">Please Enter SubDomain Name</div>
                </div>
              </div>
              <div class="input-class">
                <label>Email ID<span class="red-asterisk">*</span></label>
                <input type="text" class="form-control" formControlName="emailId" min="0">
                <div *ngIf="f.emailId.invalid && (f.emailId.dirty || f.emailId.touched)" class="alert alert-danger">
                  <div *ngIf="f.emailId.errors?.required">Please Enter email ID </div>
                  <div *ngIf="f.emailId.errors?.pattern">Please Enter Valid Email ID</div>
                  <!-- <div *ngIf="f.emailId.errors?.maxlength">Email ID should be 124 characters.</div> -->
                </div>
              </div>
              <div class="input-class">
                <label>Password<span class="red-asterisk">*</span></label>
                <input type="text" class="form-control" [type]="hide ? 'password' : 'text'" formControlName="password" maxlength="20">
                <div *ngIf="clientForm.value.password.length===20" class="alert alert-danger">Password should be between 6 to 20 characters </div>
                <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)" class="alert alert-danger">
                  <div *ngIf="f.password.errors?.required">Please enter valid password </div>
                  <div *ngIf="f.password.errors?.minlength">Password should be between 6 to 20 characters </div>
                  <div *ngIf="f.password.errors?.maxlength">Password should be between 6 to 20 characters </div>
                  <div *ngIf="f.password.errors?.pattern">Characters which contain at least one special char, one numeric digit, one uppercase and one
                    lowercase letter.</div>
                </div>
              </div>
              <div class="input-class">
                <label>Confirm Password<span class="red-asterisk">*</span></label>
                <input type="text" class="form-control" [type]="hide ? 'password' : 'text'" formControlName="confirmPassword">
                <div *ngIf="f.confirmPassword.invalid && (f.confirmPassword.dirty || f.confirmPassword.touched)" class="alert alert-danger">
                  <div *ngIf="f.confirmPassword.errors?.required">Please enter valid confirm password </div>
                  <div *ngIf="f.confirmPassword.errors?.mustMatch">Password doesn't match </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
