import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public isHeaderVisible$: BehaviorSubject<boolean>;

  constructor(private http: HttpClient) {
    this.isHeaderVisible$ = new BehaviorSubject<boolean>(false);
  }

  login(userName: string, password: string): Observable<any> {
    // const headers = { 'content-type': 'application/json' };
    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessTokenFromStoarge}`).set('content-type', 'application/json');

    const loginapiUrl = environment.apiUrl + 'superuserlogin';
    const loginData = {
      emailId: userName,
      password: password,
    };
    const loginDataToApi = JSON.stringify(loginData);
    return this.http
      .post<any>(loginapiUrl, loginDataToApi, { headers: headers })
      .pipe(
        tap((user: any) => {
          if (user && user.data) {
            localStorage.setItem('currentUser', JSON.stringify(user.data));
          }
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: any): any {
    console.error(error);
    return throwError(error);
  }
}
