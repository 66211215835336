import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { CollectionService } from '../shared/collection.service';
import { UpdateTestCreditsComponent } from './update-test-credits/update-test-credits.component';
import { NotificationService } from '../shared/notification.service';

export interface ClientData {
  clientName: string;
  clientUrl: string;
  email: any;
  phone: number;
  active: boolean;
}



@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements AfterViewInit, OnInit {

  searchValue: any = '';
  noDataFound = false;
  showclientData: any;

  // variable declaration
  displayedColumns: string[] = ['clientName', 'clientUrl', 'phoneNumber', 'status', 'Updated', 'action'];
  dataSource: MatTableDataSource<ClientData>;
  updateCreditSubscription: Subscription;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('updateCredits') updateCredits: UpdateTestCreditsComponent;

  constructor(private collectionService: CollectionService,
    private notificationService: NotificationService,
    public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.getClientData();
  }

  getClientData() {
    this.collectionService.getClient().subscribe(clientData => {
      // console.log('client data', clientData);
      this.showclientData = clientData?.items;
      console.log(this.showclientData);
      if (this.showclientData.length < 1) {
        this.noDataFound = true;
      }
      // this.dataSource = new MatTableDataSource(clientData?.items);
      this.showclientData = new MatTableDataSource(this.showclientData);
      this.showclientData.sort = this.sort;
      this.showclientData.paginator = this.paginator;
    });
  }

  openModel(data: any) {
    const updateData = data;
    this.updateCredits.showPopup(data.testCredits);
    if (this.updateCreditSubscription) { this.updateCreditSubscription.unsubscribe() }
    this.updateCreditSubscription = this.updateCredits.onClose.subscribe((data) => {
      const updateTestCredits: Subscription = this.collectionService.additionalCredits({ testCredits: data.value }, updateData.clientId).subscribe((resp) => {
        if (resp && resp.message) {
          this.notificationService.success(resp?.message);
        }
        this.getClientData();
        updateTestCredits.unsubscribe();
      })
    })
  }

  ngAfterViewInit(): void {
    this.showclientData.paginator = this.paginator;
    this.showclientData.sort = this.sort;

  }

  applyFilter(inputValue: any): any {
    console.log(inputValue);
    const filterValue = inputValue;
    // console.log(filterValue);
    this.showclientData.filter = filterValue.trim().toLowerCase();

    if (this.showclientData.filter.length < 1) {
      this.noDataFound = true;
    }

    if (this.showclientData.paginator) {
      this.showclientData.paginator.firstPage();
    }
  }

  resetFilter() {
    this.searchValue = "";

    this.collectionService.getClient().subscribe(clientData => {
      this.showclientData = clientData?.items;
      console.log(this.showclientData);
      if (this.showclientData.length < 1) {
        this.noDataFound = true;
      }

      this.showclientData = new MatTableDataSource(this.showclientData);
      this.showclientData.sort = this.sort;
      this.showclientData.paginator = this.paginator;
    });
  }
}
