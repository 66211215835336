<div class="users-container main-content-wrapper">
  <div class="content-wrapper">
    <div class="filter-section">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-9">
            <div class="input-fields-wrapper">
              <div class="input-wrapper">
                <input class="form-control" placeholder="Search Here" (keyup.enter)="applyFilter(input.value)" #input [(ngModel)]="searchValue">

              </div>
              <div class="btn-wrapper">
                <button class="btn-bg-color" mat-raised-button (click)="applyFilter(input.value)">
                  <span>Search</span>
                </button>
                <button class="btn-green-text" (click)="resetFilter()" mat-raised-button>
                  <span>Reset</span>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="search btn-wrapper">
              <button mat-raised-button routerLink="/registerclient" class="btn-orange">
                <span>+ Add Client</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="table-container">
            <table mat-table [dataSource]="showclientData" matSort class="mat-elevation-z8">

              <ng-container matColumnDef="clientName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let row">
                  <a class="">{{row?.clientName | titlecase}}</a>
                </td>
              </ng-container>

              <ng-container matColumnDef="clientUrl">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Url </th>
                <td mat-cell *matCellDef="let row">{{row?.clientUrl}} </td>
              </ng-container>

              <!-- <ng-container matColumnDef="email">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                                    <td mat-cell *matCellDef="let row">
                                        {{row?.email}}
                                    </td>
                                </ng-container> -->

              <ng-container matColumnDef="phoneNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone Number </th>
                <td mat-cell *matCellDef="let row">
                  {{row?.phone}}
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let row">
                  <i *ngIf="row?.active === true" class="fa fas fa-circle publish" matTooltip="Active" matTooltipPosition="right"></i>
                  <span *ngIf="row?.active === false" class="glyphicon glyphicon-adjust unpublish" matTooltip="Inactive"
                    matTooltipPosition="right"></span>
                </td>
              </ng-container>
              <ng-container matColumnDef="Updated">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated </th>
                <td mat-cell *matCellDef="let row"> {{ row?.modifiedDateTime | dateAgo}} </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
                <td mat-cell *matCellDef="let row">
                  <button mat-raised-button class="btn-orange" (click)="openModel(row)">
                    <span>+ Add test Credits</span>
                  </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <div class="data-not-found-container" *ngIf='noDataFound'>
              <p>Data not found "{{input.value}}"</p>
            </div>

          </div>
          <div class="client-pagination">
            <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<update-test-credits #updateCredits></update-test-credits>
