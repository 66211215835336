
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class GlobalInterceptorService implements HttpInterceptor {
  subdomain: string;
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log(request.headers);
    this.getSubdomain();
    console.log(this.subdomain);
    const r = request.clone({
      setHeaders: { subDomainName: this.subdomain },
    });
    console.log(r.headers);
    return next.handle(r);
  }

  getSubdomain() {
    const domain = window.location.hostname;
    console.log(window.location);

    if (
      domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'example' ||
      domain.split('.')[0] === 'lvh' ||
      domain.split('.')[0] === 'www'
    ) {
      this.subdomain = 'tadev-admin-cp';
    } else {
      this.subdomain = domain.split('.')[0];
    }

    console.log('subdomain', this.subdomain);
    return this.subdomain;
  }
}
