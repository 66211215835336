import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { AddClientComponent } from './add-client/add-client.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TanayMatarialModule } from './material/material.module';
import { ClientListComponent } from './client-list/client-list.component';
import { HeaderComponent } from './header/header.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { GlobalInterceptorService } from './shared/global-interceptor.service';
import { UpdateTestCreditsComponent } from './client-list/update-test-credits/update-test-credits.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HomeComponent,
    AddClientComponent,
    PageNotFoundComponent,
    ClientListComponent,
    HeaderComponent,
    DateAgoPipe,
    UpdateTestCreditsComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    TanayMatarialModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {provide:HTTP_INTERCEPTORS, useClass: GlobalInterceptorService,multi:true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
