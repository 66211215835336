import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CollectionService {

  constructor(private http: HttpClient) { }

  //  this function is fetching languages data from api
  getLanguages(): Observable<any> | null {
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // const headers = { 'content-type': 'application/json', 'token': accessTokenFromStoarge };
      const headers = new HttpHeaders().set('Authorization', `Bearer ${accessTokenFromStoarge}`).set('content-type', 'application/json');

      const apiUrl = environment.apiUrl + 'languages';
      return this.http.get<any>(apiUrl, { 'headers': headers }).pipe(
        tap((data) => data),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {

            return throwError(new Error(error.status));
          }
          if (error.status === 0) {
            return throwError(null);
          }

          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  // this function is fetching time zones data from api
  getTimeZone(): Observable<any> | null {
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // const headers = { 'content-type': 'application/json', 'token': accessTokenFromStoarge };
      const headers = new HttpHeaders().set('Authorization', `Bearer ${accessTokenFromStoarge}`).set('content-type', 'application/json');

      const apiUrl = environment.apiUrl + 'timezone';
      return this.http.get<any>(apiUrl, { 'headers': headers }).pipe(
        tap((data) => data),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {

            return throwError(new Error(error.status));
          }
          if (error.status === 0) {
            return throwError(null);
          }

          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  //  this function is posting collection data to api
  postCollection(userForm: any): Observable<any> | null {
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const clientId = currentUserFromStorage?.clientId;
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // const headers = { 'content-type': 'application/json', 'token': accessTokenFromStoarge };
      const headers = new HttpHeaders().set('Authorization', `Bearer ${accessTokenFromStoarge}`).set('content-type', 'application/json');

      const apiUrl = environment.apiUrl + 'clients/' + clientId + '/collections';
      const body = userForm;
      return this.http.post<any>(apiUrl, body, { 'headers': headers }).pipe(
        tap((data) => data),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {

            return throwError(new Error(error.status));
          }
          if (error.status === 0) {
            return throwError(null);
          }

          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  //  this function is get all collection data from api
  getCollection(): Observable<any> | null {
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const clientId = currentUserFromStorage?.clientId;
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // const headers = { 'content-type': 'application/json', 'token': accessTokenFromStoarge };
      const headers = new HttpHeaders().set('Authorization', `Bearer ${accessTokenFromStoarge}`).set('content-type', 'application/json');

      const apiUrl = environment.apiUrl + 'clients/' + clientId + '/collections';
      return this.http.get<any>(apiUrl, { 'headers': headers }).pipe(
        tap((data) => data),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {

            return throwError(new Error(error.status));
          }
          if (error.status === 0) {
            return throwError(null);
          }

          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }
  // this function is used registering new client for tanay assessment
  registerClient(clientForm: any): Observable<any> | null {
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // const headers = { 'content-type': 'application/json', 'token': accessTokenFromStoarge };
      const headers = new HttpHeaders().set('Authorization', `Bearer ${accessTokenFromStoarge}`).set('content-type', 'application/json');

      const apiUrl = environment.apiUrl + 'mundrisoft/clients';
      const body = clientForm;
      return this.http.post<any>(apiUrl, body, { 'headers': headers }).pipe(
        tap((data) => data),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {

            return throwError(new Error(error.status));
          }
          if (error.status === 0) {
            return throwError(null);
          }

          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  additionalCredits(data, updateClientId: number): Observable<any> {
    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessTokenFromStoarge}`).set('content-type', 'application/json');
    return this.http.put<any>(environment.apiUrl + `clients/AdditionalCredits/${updateClientId}`, data, { 'headers': headers });
  }

  //  this function is get all collection data from api
  getClient(): Observable<any> | null {
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // const headers = { 'content-type': 'application/json', 'token': accessTokenFromStoarge };
      const headers = new HttpHeaders().set('Authorization', `Bearer ${accessTokenFromStoarge}`).set('content-type', 'application/json');

      const apiUrl = environment.apiUrl + 'clients';
      return this.http.get<any>(apiUrl, { 'headers': headers }).pipe(
        tap((data) => data),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {

            return throwError(new Error(error.status));
          }
          if (error.status === 0) {
            return throwError(null);
          }

          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  getLanguage() {
    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    // const headers = { 'content-type': 'application/json', 'token': accessTokenFromStoarge };
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessTokenFromStoarge}`).set('content-type', 'application/json');


    return this.http.get<any>(environment.apiUrl + 'languages', { 'headers': headers });
  }
}
