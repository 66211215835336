import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CollectionService } from '../shared/collection.service';
import { NotificationService } from '../shared/notification.service';
import { MustMatch } from './../helper/must-match.validators';
import { GlobalInterceptorService } from '../shared/global-interceptor.service';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {
  timeZoneData$: Subscription | undefined;
  timeZoneArr = [];
  clientForm: FormGroup;
  hide = true;
  languageData = [];
  constructor(private router: Router,
    private collectionService: CollectionService,
    private fb: FormBuilder,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.timeZoneData$ = this.collectionService.getTimeZone()?.subscribe((timeZoneData: any) => {
      this.timeZoneArr = timeZoneData?.items;
    });
    this.clientForm = this.fb.group({
      clientName: ['',
        [Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern(/^[a-zA-Z.-]+$/)]
      ],
      clientUrl: ['',
        [Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30)]
      ],
      langid: [0],
      emailId: ['',
        [Validators.required,
        Validators.maxLength(124),
        Validators.pattern(/^[a-zA-Z0-9]+([.]?[_]?[a-zA-Z0-9])*@[a-zA-Z0-9]+([\-\.]?[a-zA-Z0-9]+)*\.[A-Za-z]{1,3}$/)]
      ],
      timezoneName: ['',
        [Validators.required]
      ],
      testcredits: ['',
        [Validators.required,
        // Validators.max(1000000),
        Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)
        ]
      ],
      maxnumberofusers: ['',
        [Validators.required]
      ],
      evaluationMode: ["2",
      [Validators.required]
      ],
      firstName: ['',
        [Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern(/^[a-zA-Z.-]+$/)]
      ],
      lastName: ['',
        [Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern(/^[a-zA-Z.-]+$/)]
      ],
      phone: ['',
        [Validators.required,
        Validators.maxLength(24),
        Validators.pattern(/^\s*(?:\+?(\d{0,3}))?[- \(]*(\d{1,9})[-. \/)]*(\d{1,9})[-. \/]*(\d{1,9})*[-. \/]*(\d{1,9})(?: *x(\d))?\s*$/)]
      ],
      password: ['',
        Validators.compose(
          [Validators.required,
          Validators.minLength(6),
          Validators.maxLength(50),
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{0,20}$/)
          ])],
      subDomainName: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],

    }, {
      validator: MustMatch('password', 'confirmPassword')
    });

    this.collectionService.getLanguage().subscribe(data => {
      this.languageData = data.items;
    });
  }


  // This function used for validation purpose
  get f(): any {
    return this.clientForm.controls;
  }

  enforceMaxLength(value: string, controlName: string) {
    const control = this.clientForm.get(controlName);
    if (value.length > 30 && (controlName === 'clientName' || controlName === 'clientUrl' || controlName === 'firstName' || controlName === 'lastName')) {
      control.setValue(value.substring(0, 30));
    } else if (value.length > 7 && controlName === 'testcredits') {
      control.setValue(value.substring(0, 7));
    } else if (value.length > 24 && controlName === 'phone') {
      control.setValue(value.substring(0, 24));
    }
  }

  registerClient(): void {
    delete this.clientForm.value.confirmPassword;
    this.clientForm.value.langid = parseInt(this.clientForm.value.langid);
    this.clientForm.value.evaluationMode = parseInt(this.clientForm.value.evaluationMode);
    const { confirmPassword, ...payload } = this.clientForm.value;
    if (this.clientForm.valid) {
      this.collectionService.registerClient(payload)?.subscribe(data => {
        if (data?.success) {
          this.notificationService.success(data?.message);
          this.router.navigate(["/clientlist"]);
        } else {
          this.notificationService.warn(data?.message);
        }
      },
      (error: any) => {
        console.log(error, 'error ');
          this.notificationService.warn(error?.error?.message);
        })
    }
  }
}

